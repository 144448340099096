import Avatar from 'antd/lib/avatar/avatar';
import React, { Component } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Input, Form, Button } from 'antd';
import { getLoginState, loginWithApi } from "../../actions/login"
import Checkbox from 'antd/lib/checkbox/Checkbox';

// Styles 
import"./styles/login.css";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class LoginView extends Component {


    componentDidMount() {
        this.props.getLoginState()
    }
    

    onFinish = (props,values) => {
        if (props.login.is_loading) {
            return;
        }


        if (values.errorFields == null) {
            this.props.loginWithApi(props,values.username,values.password)
        }
    };

    onFinishFailed = (errorInfo) => {
    };

    render() {
        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 24 },
          };
          const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
          };

          if (this.props.login.is_login) {
              this.props.history.push("/home")
          }
          

        return (
            <div className="login-wrapper">
                <div className="login-credentials">
                    <Avatar size={64} shape="circle" icon={<UserOutlined />}></Avatar>
                    <br></br>
                    <p>Login to <strong>Viva VPN</strong></p>
                    <br></br>
                    <div className="login-form">
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={(values) => this.onFinish(this.props, values)}
                        onFinishFailed={(values) => this.onFinish(this.props, values)}
                        >
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item  name="remember" valuePropName="checked">
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={this.props.login.is_loading}>
                                Login
                            </Button>
                        </Form.Item>
                        </Form>
                        </div>
                </div>
            </div>
        );
    }
}


const mapPropsToState = (props) => {
    return { 
        login: props.login
    }
}

export default withRouter(connect(mapPropsToState, { getLoginState, loginWithApi })(LoginView));