import React, { Component } from 'react';
import { DeleteOutlined, EditOutlined, FastForwardFilled, FileAddOutlined, FolderAddOutlined, GlobalOutlined, KeyOutlined, LoadingOutlined, LogoutOutlined, SettingOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown, Menu, message, PageHeader , Form, Input } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeAdminPassword } from "../actions/change_password"

class HeaderView extends Component {



    constructor(props) {
        super(props)
        this.state = {
            drawer: false
        }
    }


    proceedChangePassword = (values) => {
        if (values.cp && values.np) {
            this.props.changeAdminPassword(values.cp,values.np)
        }
    }
      



    render() {


        const menu = (
            <Menu >
              <Menu.Item  key="1" icon={<KeyOutlined />} onClick={() => this.setState({drawer: true})}>
                Change Password
              </Menu.Item>
              <Menu.Item key="2" icon={<LogoutOutlined />} onClick={() => {
                        localStorage.removeItem("_access_token");
                        this.props.history.push("/")
                    }}>
                Logout
              </Menu.Item>
            </Menu>
          );



        return (
                <div className="site-page-header-ghost-wrapper">
                    <Drawer title="Change Admin Password" visible={this.state.drawer} onClose={() => this.setState({drawer: false})}>
                        <Form onFinish={this.proceedChangePassword} >
                            <Form.Item name="cp" required rules={[{
                                message: "Your current password is required",
                            }]}>
                                <Input type="password" placeholder="Your current password"></Input>
                            </Form.Item>
                            <Form.Item name="np" required rules={[{
                                message: "Your password must be between 6 up to 128 characters long",
                                min: 6,
                                max: 128
                            }]} tooltip="Choose a strong password">
                                <Input type="password" placeholder="Your new password" ></Input>
                            </Form.Item>
                            <Form.Item name="confirm"  required rules={[{
                                message: "Confirm password is required"
                            }, ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('np') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject('The two passwords that you entered do not match!');
                                },
                              }),]} dependencies={['np']} tooltip="Choose a strong password">
                                <Input type="password" placeholder="Confirm new password" ></Input>
                            </Form.Item>
                            <p className="desc">Note: Please choose a strong password that includes Special characters, Lowercase / uppercase and digits to prevent attacks.</p>
                            <Button htmlType="submit" loading={this.props.password.is_loading} type="primary">Change Password</Button>
                        </Form>
                    </Drawer>
                    <PageHeader
                    ghost={false}
                    title="VIVA VPN"
                    subTitle="Adminstration Panel"
                    extra={[
                        <Button key="3" onClick={() => {
                            this.props.history.push("home")
                        }}>Servers</Button>,
                        <Button key="2" onClick={() => {
                            this.props.history.push("users")
                        }}>Manage Users</Button>,
                        <Dropdown.Button type="primary" icon={<UserOutlined></UserOutlined>} overlay={menu}>
                            Admin
                        </Dropdown.Button>
                    ]}
                    >
                    </PageHeader>
                </div>
        );
    }
}
const mapPropsToState = (props) => {
    return {
        password: props.password
    }
}

export default connect(mapPropsToState , { changeAdminPassword })(withRouter(HeaderView));